import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AdminUserDto, AdminUserListDto, InviteUserRequest, LastLoginListDto, UpdateUserRequest } from "api-shared";
import { useDispatch } from "react-redux";
import { showNotificationEvent } from "../../infrastructure/notifications";
import { apiDelete, apiGet, apiPatch, apiPost } from "../../lib/api";
import { NotificationType } from "../../lib/notifications";
import { FeedbackTranslationKeys } from "../../translations/notification-translations";
import { GroupsQueryKeys } from "../group";
import { UsersQueryKeys } from "../users";

const ADMIN_USERS_PATH = "admin/users";
const ADMIN_USERS_LAST_LOGINS_PATH = `${ADMIN_USERS_PATH}/last-logins`;

export const useAdminUsers = () => {
    return useQuery({
        queryKey: [ADMIN_USERS_PATH],
        queryFn: ({ signal }) => apiGet<AdminUserListDto>(ADMIN_USERS_PATH, { signal }),
    });
};

export const useLastLogins = () => {
    return useQuery({
        queryKey: [ADMIN_USERS_LAST_LOGINS_PATH],
        queryFn: ({ signal }) => apiGet<LastLoginListDto>(`${ADMIN_USERS_PATH}/last-logins`, { signal }),
    });
};

export const useAdminInviteUser = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (data: InviteUserRequest) => apiPost<AdminUserDto>(ADMIN_USERS_PATH, data),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_USERS_PATH]);
            queryClient.invalidateQueries(UsersQueryKeys.list());
            queryClient.invalidateQueries(GroupsQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_INVITED));
        },
    });
};

export const useAdminResendInvitationUser = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiPost<AdminUserDto>(`${ADMIN_USERS_PATH}/${id}/resend-invitation`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_USERS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_INVITED));
        },
    });
};

export const useAdminDeleteUser = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiDelete(`${ADMIN_USERS_PATH}/${id}`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_USERS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_DELETED));
        },
    });
};

export const useAdminUpdateUser = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: ({ id, ...changes }: Partial<UpdateUserRequest> & Pick<AdminUserDto, "id">) =>
            apiPatch<UpdateUserRequest>(`${ADMIN_USERS_PATH}/${id}`, changes),
        onSuccess: (response) => {
            queryClient.invalidateQueries([ADMIN_USERS_PATH]);
            queryClient.invalidateQueries(UsersQueryKeys.all);
            queryClient.invalidateQueries(GroupsQueryKeys.all);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_UPDATED));
        },
    });
};

export const useAdminUserResetPassword = () => {
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiPost(`${ADMIN_USERS_PATH}/${id}/reset-password`),
        onSuccess: () => {
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_PASSWORD_RESET_SENT));
        },
    });
};

export const useAdminInviteExistingUser = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiPost(`${ADMIN_USERS_PATH}/${id}/reset-password`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_USERS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_USER_INVITED));
        },
    });
};

export const useAdminTFAReset = () => {
    const queryClient = useQueryClient();
    const dispatch = useDispatch();
    return useMutation({
        mutationFn: (id: number) => apiPost(`${ADMIN_USERS_PATH}/${id}/reset-2fa`),
        onSuccess: () => {
            queryClient.invalidateQueries([ADMIN_USERS_PATH]);
            dispatch(showNotificationEvent(NotificationType.SUCCESS, FeedbackTranslationKeys.VDLANG_FEEDBACK_RESET_2FA));
        },
    });
};
